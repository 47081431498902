import { lazy } from "react";
import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";
import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

//  ============== ADMIN ROUTES START ================================================================
// ------------------Users ---------------

const AdminLogin = Loadable(lazy(() => import("./views/auth/JwtLogin")));
const User = Loadable(lazy(() => import("./views/user/UserList")));
const VideoVerification = Loadable(lazy(() => import("./views/user/videoVerification")));
const EditVideo = Loadable(lazy(() => import("./views/user/viewVideo")));

const CustomerDetails = Loadable(
  lazy(() => import("./views/user/CustomerDetails"))
);
// ------------- Dispute ---------------------

const DisputeReason = Loadable(
  lazy(() => import("./views/DisputeReason/ResonList"))
  
);
const Dispute = Loadable(
  lazy(() => import("./views/Dispute/ResonList"))
  
);
const LiveChat = Loadable(
  lazy(() => import("./views/Dispute/LiveChat"))
  
);
const  ContractList = Loadable(
  lazy(() => import("./views/contract/contractList"))
  
);
const InviteList = Loadable(
  lazy(() => import("./views/contract/inviteList"))
  
);
const ContractDetails = Loadable(
  lazy(() => import("./views/contract/Details"))
  
);
const WithdrawDetails = Loadable(
  lazy(() => import("./views/payment/withdrawdetails"))
  
);
const PaymentDetails = Loadable(
  lazy(() => import("./views/payment/paymentDetails"))
  
);
const WalletDetails = Loadable(
  lazy(() => import("./views/payment/walletDetails"))
  
);
const NewDispute = Loadable(
  lazy(() => import("./views/Dispute/AddDispute"))
);
const EditList = Loadable(
  lazy(() => import("./views/Dispute/EditList"))
);
// --------User Activity --------------------
const ActivityList = Loadable(
  lazy(() => import("./views/activity/ActivityList"))
);
const GetActivity = Loadable(
  lazy(() => import("./views/activity/GetActivity"))
);
const ActivityDetails = Loadable(
  lazy(() => import("./views/activity/ActivityDetails"))
);
const EditActivity = Loadable(
  lazy(() => import("./views/activity/EditActivity"))
);

const PaymentRequest = Loadable(
  lazy(() => import("./views/payment/paymentRequest"))
  
);

const WithdrawRequest = Loadable(
  lazy(() => import("./views/payment/withdrawRequest"))
  
);
const PaymentTransaction = Loadable(
  lazy(() => import("./views/payment/paymentTransaction"))
  
);
const WalletTransaction = Loadable(
  lazy(() => import("./views/payment/walletTransaction"))
  
);
const Transaction = Loadable(
  lazy(() => import("./views/payment/transaction"))
  
);

const UserInfo = Loadable(
  lazy(() => import("./components/UserInfo/Details"))
  
);

// New Ewarning 

const AllContracts = Loadable(
  lazy(() => import("./views/NewEarning/AllContracts"))
  
);
const TransactionHistory = Loadable(
  lazy(() => import("./views/NewEarning/TransactionHistory"))
  
);
const Earning = Loadable(
  lazy(() => import("./views/NewEarning/Earning"))
  
);
const Chat = Loadable(
  lazy(() => import("./views/NewEarning/Chat"))
  
);
const Disputes = Loadable(
  lazy(() => import("./views/NewEarning/Disputes"))
  
);
const Requests = Loadable(
  lazy(() => import("./views/NewEarning/Requests"))
  
);



// const UpdateProfile = Loadable(
//   lazy(() => import("./views/updateprofile"))
// );

//------- session pages-------
const NotFound = Loadable(lazy(() => import("app/views/auth/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/auth/JwtLogin")));
const JwtRegister = Loadable(lazy(() => import("app/views/auth/JwtRegister")));
const ForgotPassword = Loadable(lazy(() => import("app/views/auth/ForgotPassword")));
const ForgotPasswordOTPVerify = Loadable( lazy(() => import("app/views/auth/ForgotPasswordOtpVerify")));
const SetNewPassword = Loadable( lazy(() => import("app/views/auth/SetNewPassword")));
// dashboard page
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));
// user profile
const UserProfile = Loadable(
  lazy(() => import("app/views/adminprofile/Profile"))
);

// ================== ADMIN ROUTES END =======================================================

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/dashboard",
        element: <Analytics />,
        auth: authRoles.admin,
      },
      {
        path: "admin/profile",
        element: <UserProfile />,
        auth: authRoles.admin,
      },
      {
        path: "/user/list",
        element: <User />,
        auth: authRoles.admin,
      },
      {
        path: "/user/videoVerification",
        element: <VideoVerification />,
        auth: authRoles.admin,
      },
      {
        path: "/user/video/:id",
        element: <EditVideo />,
        auth: authRoles.admin,
      },
     
      {
        path: "/dispute/reason",
        element: <Dispute />,
        auth: authRoles.admin,
      },
      {
        path: "/DisputeReason/list",
        element: <DisputeReason />,
        auth: authRoles.admin,
      },

      {
        path: "/dispute/LiveChat",
        element: <LiveChat />,
        auth: authRoles.admin,
      },
      
      {
        path: "/contracts/contractsList",
        element: <ContractList />,
        auth: authRoles.admin,
      },
      {
        path: "/contracts/inviteList",
        element: <InviteList />,
        auth: authRoles.admin,
      },
      {
        path: "/contracts/details/:id",
        element: <ContractDetails />,
        auth: authRoles.admin,
      },
      {
        path: "/withdraw/details/:id",
        element: <WithdrawDetails />,
        auth: authRoles.admin,
      },
      {
        path: "/customer/details/:id",
        element: <CustomerDetails />,
        auth: authRoles.admin,
      },
      {
        path: "/payment/details/:id",
        element: <PaymentDetails />,
        auth: authRoles.admin,
      },
      {
        path: "/wallet/details/:id",
        element: <WalletDetails />,
        auth: authRoles.admin,
      },
      
      {
        path: "/dispute/new",
        element: <NewDispute />,
        auth: authRoles.admin,
      },
      {
        path: "/Dispute/EditList/:id",
        element: <EditList />,
        auth: authRoles.admin,
      },
      {
        path: "/activity/edit/:id",
        element: <EditActivity />,
        auth: authRoles.admin,
      },
      {
        path: "/activity/details/:id",
        element: <ActivityDetails />,
        auth: authRoles.admin,
      },
      {
        path: "/activity/list",
        element: <ActivityList />,
        auth: authRoles.admin,
      },
      {
        path: "/activity/list/:id",
        element: <GetActivity />,
        auth: authRoles.admin,
      },

      {
        path: "/payment/paymentrequest",
        element: <PaymentRequest />,
        auth: authRoles.admin,
      },
      
      {
        path: "/payment/withdrawrequest",
        element: <WithdrawRequest />,
        auth: authRoles.admin,
      },

      {
        path: "/payment/paymenttransaction",
        element: <PaymentTransaction />,
        auth: authRoles.admin,
      },
      {
        path: "/payment/walletTransaction",
        element: <WalletTransaction />,
        auth: authRoles.admin,
      },

      {
        path: "/payment/transaction",
        element: <Transaction />,
        auth: authRoles.admin,
      },

      {
        path: "/userInfo/details",
        element: <UserInfo />,
        auth: authRoles.admin,
      },

      // New Earning 
      
      {
        path: "/earning/all-contracts",
        element: <AllContracts />,
        auth: authRoles.admin,
      },
      {
        path: "/earning/transaction-history",
        element: <TransactionHistory />,
        auth: authRoles.admin,
      },
      {
        path: "/earning/earning",
        element: <Earning />,
        auth: authRoles.admin,
      },
      {
        path: "/earning/chat",
        element: <Chat />,
        auth: authRoles.admin,
      },
      {
        path: "/earning/disputes",
        element: <Disputes />,
        auth: authRoles.admin,
      },
      {
        path: "/earning/requests",
        element: <Requests />,
        auth: authRoles.admin,
      },


      // {
      //   path: "/updateprofile",
      //   element: <UpdateProfile />,
      //   auth: authRoles.admin,
      // },
    ],
  },
  // session pages route
  { path: "/page/404", element: <NotFound /> },
  { path: "/admin/login", element: <JwtLogin /> },
  { path: "/auth/signup", element: <JwtRegister /> },
  { path: "/auth/forgot-password", element: <ForgotPassword /> },
  { path: "/auth/forgot-password-OTP-Verify", element: <ForgotPasswordOTPVerify /> },
  { path: "/auth/set-new-password", element: <SetNewPassword /> },
  { path: "/", element: <AdminLogin /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
