import { configureStore } from '@reduxjs/toolkit';
import {authSlice} from './reducer/auth';
import { productSlice } from './reducer/ProductSlice';
import { cartSlice } from './reducer/cartSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    products: productSlice.reducer,
    cart:cartSlice.reducer
  }
});
